import { createContext, FC, PropsWithChildren } from 'react'
import { ApiQuote } from '../api/quotes/types/ApiQuote'
import { LoadingState } from '../common/types/LoadingState'
import { useQuotePdfLoading } from './hooks/useQuotePdfLoading'
import { QuotePdfData } from './types/QuotePdfData'

interface QuoteContextValue {
  quote: ApiQuote
  loadingState: LoadingState<QuotePdfData>
}

export const QuoteContext = createContext({} as QuoteContextValue)

function useQuoteContextValue(quote: ApiQuote): QuoteContextValue {
  return {
    quote,
    loadingState: useQuotePdfLoading(quote),
  }
}

export const QuoteContextProvider: FC<PropsWithChildren<{ quote: ApiQuote }>> = ({ children, quote }) => (
  <QuoteContext.Provider value={useQuoteContextValue(quote)}>{children}</QuoteContext.Provider>
)
