import { ApiCreateChatResponse } from './types/ApiCreateChatResponse'
import { AxiosProvider } from '../AxiosProvider'
import { ApiChatMessagesResponse } from './types/ApiChatMessagesResponse'

export class ApiClientChats {
  private readonly path = '/api/v1/chats'

  constructor(private provider: AxiosProvider) {}

  async getChatMessages(
    {
      chatId,
      lastMessageId,
    }: {
      chatId: string
      lastMessageId?: string
    },
    _signal?: AbortSignal,
  ): Promise<ApiChatMessagesResponse> {
    return this.provider.request({
      url: [this.path, chatId, 'messages'].join('/'),
      method: 'get',
      params: {
        lastMessageId,
        pageSize: 200,
      },
    })
  }

  async getActiveChat(): Promise<ApiCreateChatResponse> {
    return this.provider.request({
      url: [this.path, 'active'].join('/'),
      method: 'post',
    })
  }

  async cancelChat(chatId: string): Promise<ApiCreateChatResponse> {
    return this.provider.request({
      url: [this.path, chatId, 'cancel'].join('/'),
      method: 'put',
    })
  }
}
