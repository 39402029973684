import { FC } from 'react'
import { ChatDesktopPreviewItems } from './ChatDesktopPreviewItems'
import styled from '@emotion/styled'
import { ApiChatPreview } from '../../../api/chats/types/ApiChatPreview'
import { ChatDesktopPreviewTotal } from './ChatDesktopPreviewTotal'
import { ReactComponent as Logo } from '../../../logo.svg'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  isolation: 'isolate',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  gap: theme.spacing.md,
  flexGrow: 1,
  maxWidth: '100%',
  height: '100%',
  overflow: 'hidden',
  padding: theme.spacing.xs,
  boxShadow: theme.shadows.box,
}))

const Image = styled(Logo)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: -1,
  transform: 'translate(-50%, -50%)',
  width: 420,
  height: 420,
  color: '#E6EBF5',
})

const Items = styled('div')({
  flexGrow: 1,
  width: '100%',
  overflow: 'auto',
})

export const ChatDesktopPreview: FC<{ preview: ApiChatPreview | null }> = ({ preview }) => {
  return (
    <Container>
      <Items>
        <ChatDesktopPreviewItems items={preview ? preview.data : null} />
      </Items>

      <ChatDesktopPreviewTotal totalInfo={preview ? preview.totalInfo : null} />

      <Image />
    </Container>
  )
}
