import React, { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing.xs,
  alignItems: 'center',
  fontWeight: 'bold',
  flexShrink: 0,
}))

export const ChatDesktopTitle: FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>
}
