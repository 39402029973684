import React, { FC, PropsWithChildren, SVGLineElementAttributes } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { useChatBoxFileDropZone } from './useChatBoxFileDropZone'

const Container = styled('div')({ position: 'relative' })

const Content = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  isolation: 'isolate',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.colors.background.default + 'c2',
}))

const Text = styled('div')(({ theme }) => ({
  maxWidth: 160,
  textAlign: 'center',
  fontWeight: theme.fontWeight.medium,
}))

const DiagonalLinesImage = styled('svg')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  width: '100%',
  height: '100%',
  color: theme.colors.border.dotted,
}))

export const ChatBoxFileDropZone: FC<
  PropsWithChildren<{
    className?: string
  }>
> = ({ className, children }) => {
  const { t } = useTranslation()
  const { isDragActive, getRootProps } = useChatBoxFileDropZone()

  return (
    <Container className={className} {...getRootProps()}>
      {children}

      {isDragActive && (
        <Content>
          <Text>{t('Drag and drop the file you want to attach')}</Text>

          <DiagonalLinesImage xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
            <Line x1="0" y1="0" x2="100%" y2="100%" />
            <Line x1="0" y1="100%" x2="100%" y2="0" />
          </DiagonalLinesImage>
        </Content>
      )}
    </Container>
  )
}

const Line: FC<SVGLineElementAttributes<SVGLineElement>> = (attrs) => {
  return <line strokeDasharray={1} stroke="currentColor" vectorEffect="non-scaling-stroke" {...attrs} />
}
