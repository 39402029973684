import { Dispatch, SetStateAction, useState } from 'react'
import { useObservable } from '../../common/hooks/useObservable'
import { ChatWebsocket } from '../websocket/ChatWebsocket'
import { ChatMessage } from '../types/ChatMessage'

export function useChatContextMessages(
  websocket: ChatWebsocket,
): [ChatMessage[], Dispatch<SetStateAction<ChatMessage[]>>] {
  const [messages, setMessages] = useState<ChatMessage[]>([])

  useObservable(websocket.messages, (value) => setMessages((messages) => messages.concat(ChatMessage.fromApi(value))))

  return [messages, setMessages]
}
