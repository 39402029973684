import { FC, useContext } from 'react'
import { ChatContext } from '../context/ChatContext'
import { RxStompState } from '@stomp/rx-stomp'
import { ChatStateConnectingToast } from './ChatStateConnectingToast'
import { useBehaviorSubject } from '../../common/hooks/useBehaviorSubject'
import { ChatStateDeactivatedToast } from './ChatStateDeactivatedToast'

export const ChatState: FC = () => {
  const { websocket } = useContext(ChatContext)
  const connectionState = useBehaviorSubject(websocket.connectionState)
  const activated = useBehaviorSubject(websocket.activated)

  return activated ? (
    connectionState === RxStompState.OPEN ? null : (
      <ChatStateConnectingToast />
    )
  ) : (
    <ChatStateDeactivatedToast />
  )
}
