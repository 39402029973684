import React from 'react'
import { Chat } from './chat/Chat'
import { MemoryRouter, Route, Routes } from 'react-router-dom'
import { Root } from './common/layout/Root'
import { routePaths } from './common/routing/routePaths'
import { Toaster } from 'react-hot-toast'
import { theme } from './theme/theme'
import { ThemeProvider } from '@emotion/react'
import { GlobalStyles } from './theme/GlobalStyles'
import { Quote } from './quote/Quote'
import { ChatInit } from './chat/ChatInit'
import { PurchaseOrder } from './purchase-order/PurchaseOrder'

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <MemoryRouter>
        <Root>
          <Routes>
            <Route index element={<ChatInit />} />
            <Route path={routePaths.chat(':chatId')} element={<Chat />} />
            <Route path={routePaths.quote(':quoteId')} element={<Quote />} />
            <Route path={routePaths.purchaseOrder(':quoteId')} element={<PurchaseOrder />} />
          </Routes>
        </Root>

        <Toaster />
      </MemoryRouter>
    </ThemeProvider>
  )
}
