import { ApiClientChats } from './chats/ApiClientChats'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios'
import { env } from '../env'
import { AuthTokenFactory } from '../auth/AuthTokenFactory'
import { AxiosProvider } from './AxiosProvider'
import toast from 'react-hot-toast'
import { t } from 'i18next'
import { ApiClientQuotes } from './quotes/ApiClientQuotes'

class ApiClient implements AxiosProvider {
  axiosInstance: AxiosInstance

  chats = new ApiClientChats(this)
  quotes = new ApiClientQuotes(this)

  constructor() {
    this.axiosInstance = this.createAxiosInstance()
  }

  async request<T>(config: AxiosRequestConfig): Promise<T> {
    const response = await this.axiosInstance.request<T>(config)
    return response.data
  }

  private createAxiosInstance(): AxiosInstance {
    const instance = axios.create({
      baseURL: env.REACT_APP_API_URL,
    })

    instance.interceptors.request.use((config) => this.addAuthorizationHeader(config))
    instance.interceptors.response.use(null, async (error: AxiosError) => this.onError(error))

    return instance
  }

  private async addAuthorizationHeader(config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> {
    const token = AuthTokenFactory.getToken()

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  private onError(error: AxiosError) {
    if (error.response?.status === 401) {
      this.onUnauthorized()
    } else {
      toast.error(t('A server error has occurred.'))
    }
  }

  private onUnauthorized() {
    toast.error(t('Access to the resource is limited.'))
  }
}

export const apiClient = new ApiClient()
