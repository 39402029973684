import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../common/components/Button'
import { ChatContext } from '../../context/ChatContext'
import { ChatBoxContext } from '../ChatBoxContext'
import { ChatBoxSendMessageFactory } from '../form/ChatBoxSendMessageFactory'
import { LoadingSpinner } from '../../../common/components/LoadingSpinner'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  gap: 8,
  padding: 20,
  paddingTop: 12,
})

const Title = styled('h2')(({ theme }) => ({
  margin: 0,
  paddingBlock: theme.spacing.md,
  fontWeight: theme.fontWeight.medium,
}))

export const ChatBoxFileConfirmationBody: FC<{ file: File }> = ({ file }) => {
  const { t } = useTranslation()
  const { form, websocket } = useContext(ChatContext)
  const { formEnabled, confirmSending } = useContext(ChatBoxContext)

  return (
    <Container>
      <Title>{formEnabled ? t('The file is ready to be sent') : t('Wait a moment, the chat is not ready yet')}</Title>

      <Button
        primary
        big
        disabled={!formEnabled}
        onClick={async () => {
          const messageFile = await ChatBoxSendMessageFactory.getMessageFile(file)

          if (messageFile) {
            form.reset()

            await websocket.sendMessage({
              text: null,
              file: messageFile,
            })

            confirmSending()
          }
        }}
      >
        {formEnabled ? t('Send') : <LoadingSpinner />}
      </Button>

      <Button
        big
        onClick={() => {
          form.resetField('files')
        }}
      >
        {t('Cancel')}
      </Button>
    </Container>
  )
}
