import { FC, useMemo } from 'react'
import { format } from 'date-fns/format'
import styled from '@emotion/styled'

const Container = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.small,
  color: theme.colors.text.primary,
}))

export const ChatBoxMessageTime: FC<{ date: Date }> = ({ date }) => {
  const time = useMemo<string>(() => format(date, 'p'), [date])

  return <Container>{time}</Container>
}
