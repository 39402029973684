import { FC } from 'react'
import styled from '@emotion/styled'
import { Trans, useTranslation } from 'react-i18next'

const Container = styled('div')({
  maxWidth: 500,
  textAlign: 'center',
})

const Important = styled('span')(({ theme }) => ({
  color: theme.colors.primary.main,
}))

export const QuoteFooterDisclaimer: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Trans t={t}>
        <Important>Important:</Important> by clicking on "Create purchase order" you accept that we will create an order
        that includes the skus and prices shown in the quote.
      </Trans>
    </Container>
  )
}
