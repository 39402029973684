import { FC } from 'react'
import { Row } from '../../common/components/Row'
import { QuoteNewChatButton } from './QuoteNewChatButton'
import { QuoteButtonsDownloadQuote } from './QuoteButtonsDownloadQuote'
import { pdfSupported } from '../../common/pdfSupported'

export const QuoteHeader: FC = () => {
  return (
    <Row as="header">
      <QuoteNewChatButton />

      {pdfSupported ? <QuoteButtonsDownloadQuote /> : null}
    </Row>
  )
}
