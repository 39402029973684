import { useContext } from 'react'
import { ChatContext } from '../../context/ChatContext'
import { Accept, DropzoneState, useDropzone } from 'react-dropzone'
import { chatBoxFileAccept } from './chatBoxFileAccept'

const accept: Accept = Array.from(chatBoxFileAccept.entries()).reduce<Accept>((result, [key, value]) => {
  result[key] = value
  return result
}, {})

export function useChatBoxFileDropZone(): DropzoneState {
  const { form, connectionReady } = useContext(ChatContext)

  return useDropzone({
    accept,
    disabled: !connectionReady,
    preventDropOnDocument: true,
    noClick: true,
    async onDrop([acceptedFile]) {
      if (acceptedFile) {
        const dataTransfer = new DataTransfer()
        dataTransfer.items.add(acceptedFile)

        form.setValue('files', dataTransfer.files)
      } else {
        form.resetField('files')
      }

      await form.trigger('files')
    },
  })
}
