import { AuthTokenFactory } from '../../auth/AuthTokenFactory'
import { env } from '../../env'

export class ChatWebsocketUrlFactory {
  private static path = 'websocket'

  static getUrl(chatId: string): string {
    const url = new URL(this.path, env.REACT_APP_API_URL)

    url.searchParams.set('chatId', chatId)

    const authToken = AuthTokenFactory.getToken()

    if (authToken) {
      url.searchParams.set('authToken', authToken)
    }

    return url.toString()
  }
}
