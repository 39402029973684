import React, { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ChatMobilePreviewDetailData } from './ChatMobilePreviewDetail'
import { ChatMobilePreviewDetails } from './ChatMobilePreviewDetails'
import { ApiChatPreviewItem } from '../../../api/chats/types/ApiChatPreview'
import { Money } from '../../../common/components/Money'
import { ChatPreviewComment } from '../../preview/ChatPreviewComment'

export const ChatMobilePreviewItem: FC<{ item: ApiChatPreviewItem }> = ({ item }) => {
  const { t } = useTranslation()
  const details: ChatMobilePreviewDetailData[] = useMemo(
    () => [
      {
        title: t('#'),
        value: item.rownum,
      },
      { title: t('Description'), value: item.description },
      {
        title: t('Units'),
        value: item.units,
      },
      { title: t('Quantity'), value: item.quantity },
      {
        title: t('Unit Price'),
        value: <Money value={item.price} />,
      },
      { title: t('% Discount'), value: item.discount },
      {
        title: t('Subtotal'),
        value: <Money value={item.subtotal} />,
      },
      { title: t('Delivery Date'), value: item.date },
    ],
    [t, item],
  )

  return (
    <>
      <ChatMobilePreviewDetails details={details} />

      {item.comment && <ChatPreviewComment>{item.comment}</ChatPreviewComment>}
    </>
  )
}
