import { AxiosProvider } from '../AxiosProvider'
import { ApiQuotesCreateQuoteRequest } from './types/ApiQuotesCreateQuoteRequest'
import { ApiQuotesCreateQuoteResponse } from './types/ApiQuotesCreateQuoteResponse'
import { ApiQuotesCreateOrderResponse } from './types/ApiQuotesCreateOrderResponse'
import { ApiQuotesCreateOrderRequest } from './types/ApiQuotesCreateOrderRequest'
import { ApiQuotesDownloadQuoteRequest } from './types/ApiQuotesDownloadQuoteRequest'
import { ApiQuotesDownloadQuoteResponse } from './types/ApiQuotesDownloadQuoteResponse'

export class ApiClientQuotes {
  private readonly resourcePath = '/api/v1/quotes'

  constructor(private provider: AxiosProvider) {}

  async createOrder(request: ApiQuotesCreateOrderRequest): Promise<ApiQuotesCreateOrderResponse> {
    return this.provider.request({
      url: [this.resourcePath, request.quoteId, 'purchase-order'].join('/'),
      method: 'post',
    })
  }

  async downloadQuote(request: ApiQuotesDownloadQuoteRequest): Promise<ApiQuotesDownloadQuoteResponse> {
    return this.provider.request({
      url: [this.resourcePath, request.quoteId, 'download'].join('/'),
      method: 'get',
    })
  }

  async createQuote(request: ApiQuotesCreateQuoteRequest): Promise<ApiQuotesCreateQuoteResponse> {
    return this.provider.request({
      url: this.resourcePath,
      method: 'post',
      data: request,
    })
  }
}
