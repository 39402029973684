import React, { FC, useContext, useState } from 'react'
import { Button } from '../../common/components/Button'
import { useTranslation } from 'react-i18next'
import { QuoteContext } from '../QuoteContext'
import { apiClient } from '../../api/ApiClient'
import { postMessageToParent, PostMessageType } from '../../common/functions/postMessageToParent'
import { LoadingSpinner } from '../../common/components/LoadingSpinner'
import styled from '@emotion/styled'
import toast from 'react-hot-toast'

const Btn = styled(Button)({
  minWidth: '40%',
})

export const QuoteButtonsCreateOrder: FC = () => {
  const { t } = useTranslation()
  const { quote } = useContext(QuoteContext)
  const [loading, setLoading] = useState(false)

  return (
    <Btn
      primary
      big
      disabled={loading}
      onClick={async () => {
        setLoading(true)

        await apiClient.quotes
          .createOrder({ quoteId: quote.id })
          .then(() => {
            postMessageToParent(PostMessageType.QuotationCompleted)
          })
          .catch(() => {
            toast.error(t('A server error has occurred.'))
            setLoading(false)
          })
      }}
    >
      {loading ? <LoadingSpinner /> : t('Create purchase order')}
    </Btn>
  )
}
