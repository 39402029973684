import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { QuoteContext } from './QuoteContext'
import { Money } from '../common/components/Money'
import { useTranslation } from 'react-i18next'
import { ResetButton } from '../common/components/ResetButton'
import { ExternalLink } from 'lucide-react'
import { LoadingStatus } from '../common/types/LoadingStatus'
import { LoadingSpinner } from '../common/components/LoadingSpinner'
import { ErrorOccurred } from '../common/components/ErrorOccurred'

const Container = styled('div')({
  textAlign: 'center',
})
const Title = styled('h1')({
  margin: 0,
})
const List = styled('ul')({
  padding: 0,
  listStyle: 'none',
})
const Item = styled('li')(({ theme }) => ({
  margin: theme.spacing.xs,
}))
const Name = styled('span')({})
const Value = styled('span')(({ theme }) => ({
  fontWeight: theme.fontWeight.medium,
}))
const Button = styled(ResetButton)(({ theme }) => ({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 8,
  fontWeight: theme.fontWeight.medium,
  paddingBlock: 8,
  paddingInline: 24,
  fontSize: theme.fontSize.regular,
  background: theme.colors.text.primary,
  color: theme.colors.background.default,
  '&:disabled': {
    opacity: 0.3,
    background: theme.colors.text.primary,
  },
  '&:hover': {
    background: theme.colors.text.primary,
    color: theme.colors.background.default,
  },
  '&:active': {
    background: theme.colors.common.black,
  },
}))

export const QuotePdfReplacement: FC = () => {
  const {
    quote,
    loadingState: [status, data],
  } = useContext(QuoteContext)
  const { t } = useTranslation()

  return (
    <Container>
      <Title>{t('Quote created')}</Title>

      <List>
        <Item>
          <Name>{t('Budget No.')}:</Name> <Value>{quote.id}</Value>
        </Item>

        <Item>
          <Name>{t('Total')}:</Name>{' '}
          <Value>
            <Money value={quote.totalInfo.totalAmount} />
          </Value>
        </Item>
      </List>

      <Button
        disabled={status !== LoadingStatus.Success}
        onClick={() => {
          if (data) {
            window.open(data.url, '_blank')
          }
        }}
      >
        {status === LoadingStatus.Success ? (
          <>
            <ExternalLink size={16} /> {t('Open quote')}
          </>
        ) : status === LoadingStatus.Failure ? (
          <ErrorOccurred />
        ) : (
          <LoadingSpinner />
        )}
      </Button>
    </Container>
  )
}
