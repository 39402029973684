import { useMemo } from 'react'
import { ChatWebsocket } from '../websocket/ChatWebsocket'
import { useUnmount } from 'ahooks'
import { useObservable } from '../../common/hooks/useObservable'

export function useChatContextWebsocket(chatId: string): ChatWebsocket {
  const websocket = useMemo(() => new ChatWebsocket(chatId), [chatId])

  // TODO: these errors are emitted also when a chat is marked as inactive
  useObservable(websocket.errors, () => {
    websocket.deactivate()
  })

  useUnmount(async () => {
    await websocket.deactivate()
  })

  return websocket
}
