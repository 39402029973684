import { ChatBoxFormData } from '../../context/useChatContextForm'
import { ChatWebsocketSendMessage, ChatWebsocketSendMessageFile } from '../../websocket/types/ChatWebsocketSendMessage'
import { convertFileToBase64 } from '../../../common/functions/convertFileToBase64'

export class ChatBoxSendMessageFactory {
  constructor(private data: ChatBoxFormData) {}

  static async getMessageFile(file: File): Promise<ChatWebsocketSendMessageFile | null> {
    try {
      return {
        name: file.name,
        payload: await convertFileToBase64(file),
      }
    } catch (e) {
      return null
    }
  }

  async getMessage(): Promise<ChatWebsocketSendMessage> {
    return {
      text: this.data.message,
      file: await this.getFormMessageFile(),
    }
  }

  private async getFormMessageFile(): Promise<ChatWebsocketSendMessageFile | null> {
    if (this.data.files) {
      const [file] = this.data.files
      return ChatBoxSendMessageFactory.getMessageFile(file)
    }

    return null
  }
}
