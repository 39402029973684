import { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { TriangleAlert } from 'lucide-react'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  top: -2,
  display: 'inline-flex',
  alignItems: 'center',
  gap: 6,
  paddingBlock: 2,
  paddingInline: 6,
  background: theme.colors.primary.main,
  color: theme.colors.primary.contrastText,
  fontSize: theme.fontSize.small,
  textAlign: 'left',
}))

const Triangle = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '100%',
  left: 9,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 3.5px 4px 3.5px',
  borderColor: `transparent transparent ${theme.colors.primary.main} transparent`,
  transform: 'rotate(0deg)',
}))

export const ChatPreviewComment: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container>
      <Triangle />

      <TriangleAlert size={14} /> {children}
    </Container>
  )
}
