import { Observable } from 'rxjs'
import { useMemoizedFn } from 'ahooks'
import { useEffect } from 'react'

export function useObservable<T>(observable: Observable<T>, callback: (data: T) => void) {
  const fn = useMemoizedFn(callback)

  useEffect(() => {
    const subscription = observable.subscribe(fn)

    return () => {
      subscription.unsubscribe()
    }
  }, [observable, fn])
}
