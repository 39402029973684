import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../common/components/Button'
import { useChatCreateQuoteClickHandler } from '../hooks/useChatCreateQuoteClickHandler'
import { ChatContext } from '../context/ChatContext'
import { LoadingSpinner } from '../../common/components/LoadingSpinner'

export const ChatQuoteCreateButton: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const { preview } = useContext(ChatContext)
  const clickHandler = useChatCreateQuoteClickHandler()
  const [loading, setLoading] = useState(false)

  return (
    <Button
      className={className}
      primary
      big
      disabled={!preview || loading}
      onClick={() => {
        setLoading(true)

        clickHandler().finally(() => setLoading(false))
      }}
    >
      {loading ? <LoadingSpinner /> : t('Create quote')}
    </Button>
  )
}
