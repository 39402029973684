import { FC } from 'react'
import styled from '@emotion/styled'
import { LoaderCircle } from 'lucide-react'
import { animations } from '../animations'

const Container = styled('div')({
  display: 'flex',
})

const Icon = styled(LoaderCircle)({
  animation: `${animations.spin} 0.675s linear infinite`,
})

export const Spinner: FC = () => {
  return (
    <Container>
      <Icon size="1em" />
    </Container>
  )
}
