import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { animations } from '../../common/animations'

const Container = styled('div')({
  animation: `${animations.blink} 2s ease infinite`,
  ':after': {
    content: '"."',
    animation: `${animations.ellipsis} 1s ease infinite`,
  },
})

export const ChatBoxTyping: FC = () => {
  const { t } = useTranslation()

  return <Container>{t('Cotiz AI is typing')}</Container>
}
