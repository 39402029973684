import { LoadingState } from '../../common/types/LoadingState'
import { useEffect, useState } from 'react'
import { LoadingStatus } from '../../common/types/LoadingStatus'
import { apiClient } from '../../api/ApiClient'
import toBlob from 'b64-to-blob'
import { ApiQuote } from '../../api/quotes/types/ApiQuote'
import { QuotePdfData } from '../types/QuotePdfData'

export function useQuotePdfLoading(quote: ApiQuote): LoadingState<QuotePdfData> {
  const [state, setState] = useState<LoadingState<QuotePdfData>>([LoadingStatus.Loading])

  useEffect(() => {
    setState([LoadingStatus.Loading])

    apiClient.quotes
      .downloadQuote({ quoteId: quote.id })
      .then((response) => {
        const blob = toBlob(response.payload, 'application/pdf')
        const url = URL.createObjectURL(blob)
        const data: QuotePdfData = {
          blob,
          url,
          filename: response.fileName,
        }

        setState([LoadingStatus.Success, data])
      })
      .catch(() => {
        setState([LoadingStatus.Failure])
      })
  }, [quote.id])

  return state
}
