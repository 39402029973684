import { FC } from 'react'
import { ApiChatPreviewTotalInfo } from '../../../api/chats/types/ApiChatPreview'
import { useTranslation } from 'react-i18next'
import { Money } from '../../../common/components/Money'
import { ChatDesktopPreviewTable } from './ChatDesktopPreviewTable'

export const ChatDesktopPreviewTotal: FC<{ totalInfo: ApiChatPreviewTotalInfo | null }> = ({ totalInfo }) => {
  const { t } = useTranslation()

  return (
    <ChatDesktopPreviewTable>
      <thead>
        <tr>
          <th>{t('Gross')}</th>
          <th>{t('Base')}</th>
          <th>{t('VAT')}</th>
          <th>{t('Taxes')}</th>
          <th>{t('Total')}</th>
        </tr>
      </thead>

      {totalInfo && (
        <tbody>
          <tr>
            <td>
              <Money value={totalInfo.grossAmount} />
            </td>

            <td>
              <Money value={totalInfo.base} />
            </td>

            <td>{totalInfo.vat}</td>

            <td>
              <Money value={totalInfo.taxes} />
            </td>

            <td>
              <strong>
                <Money value={totalInfo.totalAmount} />
              </strong>
            </td>
          </tr>
        </tbody>
      )}
    </ChatDesktopPreviewTable>
  )
}
