import React, { FC } from 'react'
import styled from '@emotion/styled'
import { ChatBoxMessages } from './messages/ChatBoxMessages'
import { ChatBoxForm } from './form/ChatBoxForm'
import { ChatBoxFilePreview } from './file/ChatBoxFilePreview'
import { useChatBoxFile } from '../hooks/useChatBoxFile'
import { ChatBoxFileDropZone } from './file/ChatBoxFileDropZone'
import { ChatBoxContextProvider } from './ChatBoxContext'
import { ChatBoxFileConfirmation } from './file/ChatBoxFileConfirmation'

const Container = styled(ChatBoxFileDropZone)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  flexBasis: '100%',
  overflow: 'hidden',
  boxShadow: theme.shadows.box,
}))

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  padding: theme.spacing.sm,
  gap: theme.spacing.sm,
  [theme.breakpoints.desktop]: {
    padding: theme.spacing.md,
  },
}))

export const ChatBox: FC = () => {
  const file = useChatBoxFile()

  return (
    <ChatBoxContextProvider>
      <Container>
        <ChatBoxMessages />

        <Footer>
          {file && <ChatBoxFilePreview file={file} />}

          <ChatBoxForm />
        </Footer>
      </Container>

      <ChatBoxFileConfirmation />
    </ChatBoxContextProvider>
  )
}
