import styled from '@emotion/styled'
import { ResetButton } from './ResetButton'

export const Button = styled(ResetButton)<{ primary?: boolean; big?: boolean }>(
  ({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    fontWeight: theme.fontWeight.medium,
    '&:hover': {
      background: theme.colors.text.primary,
    },
    '&:active': {
      background: theme.colors.common.black,
    },
  }),
  ({ theme, primary }) =>
    primary
      ? {
          background: theme.colors.primary.main,
          color: theme.colors.primary.contrastText,
          '&:disabled': {
            background: theme.colors.primary.disabled,
          },
        }
      : {
          background: theme.colors.secondary.main,
          color: theme.colors.secondary.contrastText,
          '&:disabled': {
            background: theme.colors.secondary.disabled,
          },
        },
  ({ theme, big }) =>
    big
      ? {
          paddingBlock: 8,
          paddingInline: 20,
          fontSize: theme.fontSize.regular,
        }
      : {
          paddingBlock: 6,
          paddingInline: 12,
          fontSize: theme.fontSize.small,
        },
)
