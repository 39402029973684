import { MutableRefObject, useContext, useEffect, useRef } from 'react'
import { ChatContext } from '../../context/ChatContext'
import { useMemoizedFn } from 'ahooks'
import { useResizeObserver } from 'usehooks-ts'

export function useChatBoxMessageScrollDown(): MutableRefObject<HTMLDivElement | null> {
  const ref = useRef<HTMLDivElement | null>(null)
  const { messages } = useContext(ChatContext)
  const onResize = useMemoizedFn(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  })

  useResizeObserver({ ref, onResize })

  useEffect(onResize, [messages, onResize])

  return ref
}
