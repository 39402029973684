import { useState } from 'react'
import { useObservable } from '../../common/hooks/useObservable'
import { ApiChatPreview } from '../../api/chats/types/ApiChatPreview'
import { ChatWebsocket } from '../websocket/ChatWebsocket'

export function useChatContextPreview(websocket: ChatWebsocket): ApiChatPreview | null {
  const [preview, setPreview] = useState<ApiChatPreview | null>(null)

  useObservable(websocket.preview, setPreview)

  return preview
}
