import styled from '@emotion/styled'

export const ChatDesktopPreviewTable = styled.table(({ theme }) => ({
  width: '100%',
  margin: 0,
  borderCollapse: 'separate',
  borderSpacing: 4,
  '& th': {
    background: theme.colors.text.primary,
    color: theme.colors.common.white,
    paddingInline: 8,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '& td': {
    textAlign: 'center',
  },
}))
