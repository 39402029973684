import { useEffect } from 'react'
import toast from 'react-hot-toast'

export function useLoadingToast(content: string) {
  useEffect(() => {
    const id = toast.loading(content)

    return () => {
      toast.dismiss(id)
    }
  }, [content])
}
