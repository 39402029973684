import { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import { ChatBoxMessageContent } from './ChatBoxMessageContent'
import { ChatBoxMessageFooter } from './ChatBoxMessageFooter'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
  maxWidth: '75%',
  whiteSpace: 'pre-wrap'
})

const IncomingContainer = styled(Container)({
  textAlign: 'start',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})

const OutgoingContainer = styled(Container)({
  textAlign: 'end',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginLeft: 'auto',
})

export const ChatBoxMessageContainer: FC<
  PropsWithChildren<{
    fromAssistant: boolean
    date?: Date
  }>
> = ({ date, fromAssistant, children }) => {
  const Container = fromAssistant ? IncomingContainer : OutgoingContainer

  return (
    <Container>
      <ChatBoxMessageContent fromAssistant={fromAssistant}>{children}</ChatBoxMessageContent>
      <ChatBoxMessageFooter fromAssistant={fromAssistant} date={date} />
    </Container>
  )
}
