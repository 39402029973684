import { FC, useContext } from 'react'
import { ChatBoxMessageContainer } from './ChatBoxMessageContainer'
import { ChatContext } from '../../context/ChatContext'
import styled from '@emotion/styled'
import { useChatBoxMessageScrollDown } from './useChatBoxMessageScrollDown'
import { ChatBoxMessageFile } from './ChatBoxMessageFile'
import { ChatBoxTyping } from '../ChatBoxTyping'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100%',
  overflow: 'auto',
  padding: theme.spacing.sm,
  paddingBottom: 0,
  [theme.breakpoints.desktop]: {
    padding: theme.spacing.md,
    paddingBottom: 0,
  },
}))

export const ChatBoxMessages: FC = () => {
  const { messages, waitingResponse } = useContext(ChatContext)
  const ref = useChatBoxMessageScrollDown()

  return (
    <Container ref={ref}>
      {messages.map((message, index) => (
        <ChatBoxMessageContainer fromAssistant={message.fromAssistant} date={message.date} key={index}>
          {message.text}
          {message.file && <ChatBoxMessageFile file={message.file} />}
        </ChatBoxMessageContainer>
      ))}

      {waitingResponse && (
        <ChatBoxMessageContainer fromAssistant>
          <ChatBoxTyping />
        </ChatBoxMessageContainer>
      )}
    </Container>
  )
}
