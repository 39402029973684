import { FC } from 'react'
import styled from '@emotion/styled'
import { filesize } from 'filesize'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 6,
  maxWidth: '100%',
  paddingBlock: 3,
  paddingInline: 12,
  borderRadius: 120,
  background: theme.colors.dark.main,
  color: theme.colors.dark.contrastText,
  fontSize: theme.fontSize.extraSmall,
  whiteSpace: 'nowrap',
}))

const Name = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const Size = styled('div')({
  opacity: 0.5,
})

export const ChatBoxFileConfirmationFile: FC<{ file: File }> = ({ file }) => {
  return (
    <Container>
      <Name>{file.name}</Name>
      <Size>{filesize(file.size)}</Size>
    </Container>
  )
}
