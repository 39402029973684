import { keyframes } from '@emotion/react'

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
})

const blink = keyframes({
  '0%': {
    opacity: 1,
  },
  '50%': {
    opacity: 0.5,
  },
  '100%': {
    opacity: 1,
  },
})

const ellipsis = keyframes({
  '0%': {
    content: '"."',
  },
  '33%': {
    content: '".."',
  },
  '66%': {
    content: '"..."',
  },
  '100%': {
    content: '"."',
  },
})

export const animations = {
  spin,
  blink,
  ellipsis,
}
