import { FC, useMemo } from 'react'
import { ApiMoney } from '../../api/types/ApiMoney'

const symbolMap: { [key: string]: string } = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  JPY: '¥',
  AUD: '$',
  CAD: '$',
  CHF: 'CHF',
  CNY: '¥',
  INR: '₹',
  BRL: 'R$',
  ZAR: 'R',
  RUB: '₽',
  MXN: '$',
  SGD: 'S$',
  HKD: '$',
  NZD: '$',
  KRW: '₩',
  TRY: '₺',
  SAR: '﷼',
  AED: 'د.إ',
  SEK: 'kr',
  NOK: 'kr',
  DKK: 'kr',
  ILS: '₪',
}

export const Money: FC<{ value: ApiMoney }> = ({ value }) => {
  const symbol = useMemo(() => {
    const currency = value.currency.toUpperCase()
    return symbolMap[currency] || currency
  }, [value.currency])

  return (
    <>
      {value.value}
      {symbol}
    </>
  )
}
