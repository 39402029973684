import React, { FC } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Caption } from '../../common/components/Caption'
import { ChatBoxFileSelect } from '../box/file/ChatBoxFileSelect'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing.sm,
  flexShrink: 0,
  border: '2px dashed ' + theme.colors.primary.main,
  paddingInline: theme.spacing.md,
  paddingBlock: theme.spacing.sm,
}))

const Footer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: theme.spacing.sm,
  flexGrow: 1,
  width: '100%',
}))

const Select = styled(ChatBoxFileSelect)({
  flexGrow: 1,
})

const Help = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing.sm,
  flexShrink: 0,
}))

const HelpText = styled('div')(({ theme }) => ({
  fontSize: theme.fontSize.small,
  fontWeight: theme.fontWeight.medium,
}))

const HelpIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 20,
  height: 20,
  borderRadius: '50%',
  color: theme.colors.outgoing.contrastText,
  background: theme.colors.outgoing.background,
  fontWeight: theme.fontWeight.bold,
  fontSize: 15,
  cursor: 'help',
}))

export const ChatDesktopUpload: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Caption>{t('Attach or drag and drop a PDF, Excel, or image file.')}</Caption>

      <Footer>
        <Select />

        <Help>
          <HelpText>{t('You have doubts?')}</HelpText>

          <HelpIcon>?</HelpIcon>
        </Help>
      </Footer>
    </Container>
  )
}
