import { FC } from 'react'
import styled from '@emotion/styled'
import { QuoteButtonsCreateOrder } from './QuoteButtonsCreateOrder'
import { QuoteFooterDisclaimer } from './QuoteFooterDisclaimer'

const Container = styled('footer')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing.md,
}))

export const QuoteFooter: FC = () => {
  return (
    <Container>
      <QuoteButtonsCreateOrder />
      <QuoteFooterDisclaimer />
    </Container>
  )
}
