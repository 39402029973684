import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import toast from 'react-hot-toast'
import { Button } from '../../common/components/Button'
import styled from '@emotion/styled'
import { Row } from '../../common/components/Row'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '../../common/routing/routePaths'
import { Backdrop } from '../../common/components/Backdrop'

const Container = styled(Row)(({ theme }) => ({
  gap: theme.spacing.md,
}))

const Btn = styled(Button)({
  whiteSpace: 'nowrap',
})

export const ChatStateDeactivatedToast: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const content = (
      <Container>
        <div>{t('Chat is inactive')}</div>

        <Btn
          primary
          onClick={() => {
            navigate(routePaths.init)
          }}
        >
          {t('Connect again')}
        </Btn>
      </Container>
    )

    const toastId = toast(content, {
      duration: Infinity,
    })

    return () => {
      toast.dismiss(toastId)
    }
  }, [navigate, t])

  return <Backdrop />
}
