import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoadingToast } from '../../common/hooks/useLoadingToast'

export const ChatStateConnectingToast: FC = () => {
  const { t } = useTranslation()

  useLoadingToast(t('Connecting to the chat'))

  return null
}
