import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { ChatMobileContext } from '../ChatMobileContext'
import { Maximize, Minimize } from 'lucide-react'
import { ResetButton } from '../../../common/components/ResetButton'

const Button = styled(ResetButton)(({ theme }) => ({
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
  zIndex: 2,
  padding: theme.spacing.sm,
  '&:hover': {
    background: theme.colors.text.primary,
    color: theme.colors.background.default,
  },
  '&:active': {
    background: theme.colors.common.black,
  },
}))

export const ChatMobilePreviewMaximizedToggle: FC = () => {
  const { previewMaximized, setPreviewMaximized } = useContext(ChatMobileContext)
  const Icon = previewMaximized ? Minimize : Maximize

  return (
    <Button
      onClick={() => {
        setPreviewMaximized(!previewMaximized)
      }}
    >
      <Icon size={20} />
    </Button>
  )
}
