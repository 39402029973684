import React, { FC, useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { Button } from '../../../common/components/Button'
import { ChatContext } from '../../context/ChatContext'
import mergeRefs from 'merge-refs'
import { chatBoxFileAccept } from './chatBoxFileAccept'

const HiddenInput = styled('input')({
  display: 'none',
})

const accept = Array.from(chatBoxFileAccept.keys()).join()

export const ChatBoxFileSelect: FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation()
  const { form, connectionReady } = useContext(ChatContext)
  const elementRef = useRef<HTMLInputElement | null>(null)
  const register = form.register('files')
  const ref = mergeRefs(elementRef, register.ref)

  return (
    <>
      <HiddenInput type="file" accept={accept} {...register} ref={ref} />

      <Button
        className={className}
        onClick={() => {
          elementRef.current?.click()
        }}
        big
        disabled={!connectionReady}
      >
        {t('Select file')}
      </Button>
    </>
  )
}
