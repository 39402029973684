import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from './Spinner'

export const LoadingSpinner: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Spinner />

      {t('Loading...')}
    </>
  )
}
