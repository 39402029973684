import React, { FC } from 'react'
import { ChatContextProvider } from './context/ChatContext'
import { useParams } from 'react-router-dom'
import { useDesktopBreakpoint } from '../theme/useDesktopBreakpoint'
import { ChatDesktop } from './desktop/ChatDesktop'
import { ChatMobile } from './mobile/ChatMobile'
import { ChatState } from './state/ChatState'

export const Chat: FC = () => {
  const { chatId } = useParams() as { chatId: string }
  const desktopBreakpoint = useDesktopBreakpoint()

  return (
    <ChatContextProvider chatId={chatId} key={chatId}>
      {desktopBreakpoint ? <ChatDesktop /> : <ChatMobile />}

      <ChatState />
    </ChatContextProvider>
  )
}
