import { DispatchWithoutAction, useContext, useState } from 'react'
import { ApiChatMessageType } from '../../api/chats/types/ApiChatMessageType'
import { ChatContext } from '../context/ChatContext'

export function useChatBoxConfirmation(): [confirmed: boolean, confirm: DispatchWithoutAction] {
  const { websocket } = useContext(ChatContext)
  const [confirmed, setConfirmed] = useState(true)
  const confirm: DispatchWithoutAction = () => {
    if (confirmed) {
      setConfirmed(false)

      const subscription = websocket.messages.subscribe((value) => {
        if (value.type === ApiChatMessageType.User) {
          subscription.unsubscribe()
          setConfirmed(true)
        }
      })
    }
  }

  return [confirmed, confirm]
}
