import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { FileCheck, X } from 'lucide-react'
import { ResetButton } from '../../../common/components/ResetButton'
import { ChatContext } from '../../context/ChatContext'
import { ChatBoxFileConfirmationFile } from './ChatBoxFileConfirmationFile'

const Container = styled('header')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: 16,
  padding: 20,
  borderBottom: `1px solid ${theme.colors.common.black + '15'}`,
}))

const CloseButton = styled(ResetButton)(({ theme }) => ({
  position: 'absolute',
  top: 4,
  right: 4,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
  color: theme.colors.text.primary,
  '&:hover': {
    background: theme.colors.text.primary,
    color: theme.colors.background.default,
  },
  '&:active': {
    background: theme.colors.common.black,
    color: theme.colors.background.default,
  },
}))

export const ChatBoxFileConfirmationHeader: FC<{ file: File }> = ({ file }) => {
  const { form } = useContext(ChatContext)

  return (
    <Container>
      <FileCheck size={24} />

      <ChatBoxFileConfirmationFile file={file} />

      <CloseButton
        onClick={() => {
          form.resetField('files')
        }}
      >
        <X size={24} strokeWidth={1.5} />
      </CloseButton>
    </Container>
  )
}
