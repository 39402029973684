import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useState } from 'react'

interface ChatMobileContextValue {
  previewMaximized: boolean
  setPreviewMaximized: Dispatch<SetStateAction<boolean>>
}

export const ChatMobileContext = createContext({} as ChatMobileContextValue)

function useChatMobileContextValue(): ChatMobileContextValue {
  const [previewMaximized, setPreviewMaximized] = useState<boolean>(false)

  return { previewMaximized, setPreviewMaximized }
}

export const ChatMobileContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <ChatMobileContext.Provider value={useChatMobileContextValue()}>{children}</ChatMobileContext.Provider>
)
