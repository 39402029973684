import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ChatMobilePreviewDetailData } from './ChatMobilePreviewDetail'
import { ChatMobilePreviewDetails } from './ChatMobilePreviewDetails'
import { ApiChatPreviewTotalInfo } from '../../../api/chats/types/ApiChatPreview'
import { Money } from '../../../common/components/Money'

const Details = styled(ChatMobilePreviewDetails)(({ theme }) => ({
  border: '1px solid ' + theme.colors.common.black,
}))

export const ChatMobilePreviewTotal: FC<{ totalInfo: ApiChatPreviewTotalInfo }> = ({ totalInfo }) => {
  const { t } = useTranslation()
  const details: ChatMobilePreviewDetailData[] = useMemo(
    () => [
      {
        title: t('Summary of your quote'),
        value: null,
      },
      { title: t('Gross'), value: <Money value={totalInfo.grossAmount} /> },
      {
        title: t('Base'),
        value: <Money value={totalInfo.base} />,
      },
      {
        title: t('VAT'),
        value: totalInfo.vat,
      },
      { title: t('Taxes'), value: <Money value={totalInfo.taxes} /> },
      {
        title: t('Total'),
        value: <Money value={totalInfo.totalAmount} />,
      },
    ],
    [t, totalInfo],
  )

  return <Details details={details} />
}
