import React, { FC, useContext } from 'react'
import { Button } from '../../common/components/Button'
import { useTranslation } from 'react-i18next'
import { QuoteContext } from '../QuoteContext'
import { saveAs } from 'file-saver'
import { LoadingSpinner } from '../../common/components/LoadingSpinner'
import { Download } from 'lucide-react'
import { LoadingStatus } from '../../common/types/LoadingStatus'
import { ErrorOccurred } from '../../common/components/ErrorOccurred'

export const QuoteButtonsDownloadQuote: FC = () => {
  const { t } = useTranslation()
  const [status, data] = useContext(QuoteContext).loadingState

  return (
    <Button
      big
      disabled={status !== LoadingStatus.Success}
      onClick={async () => {
        if (status === LoadingStatus.Success) {
          saveAs(data.url, data.filename)
        }
      }}
    >
      {status === LoadingStatus.Loading ? (
        <LoadingSpinner />
      ) : status === LoadingStatus.Failure ? (
        <ErrorOccurred />
      ) : (
        <>
          <Download size={18} /> {t('Download quote')}
        </>
      )}
    </Button>
  )
}
