import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChatContext } from '../context/ChatContext'
import { apiClient } from '../../api/ApiClient'
import { routePaths } from '../../common/routing/routePaths'

export function useChatCreateQuoteClickHandler(): () => Promise<void> {
  const { chatId } = useContext(ChatContext)
  const navigate = useNavigate()

  return async () => {
    const quote = await apiClient.quotes.createQuote({ chatId })

    navigate(routePaths.quote(quote.id), {
      state: quote,
    })
  }
}
