import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { RxStompState } from '@stomp/rx-stomp'
import { ChatMessage } from '../types/ChatMessage'
import last from 'lodash/last'
import { ChatMessagesRecoveryWorker } from './ChatMessagesRecoveryWorker'
import { ChatWebsocket } from '../websocket/ChatWebsocket'
import { useObservable } from '../../common/hooks/useObservable'

export function useChatMessagesRecovery({
  chatId,
  websocket,
  messages,
  setMessages,
}: {
  chatId: string
  websocket: ChatWebsocket
  messages: ChatMessage[]
  setMessages: Dispatch<SetStateAction<ChatMessage[]>>
}): boolean {
  const recoveryWorker = useMemo(() => new ChatMessagesRecoveryWorker(chatId, setMessages), [chatId, setMessages])
  const [recovering, setRecovering] = useState(false)

  useObservable(websocket.connectionState, async (value) => {
    if (value === RxStompState.OPEN) {
      setRecovering(true)

      await recoveryWorker.recoverMessages(last(messages)).finally(() => {
        setRecovering(false)
      })
    }
  })

  return recovering
}
