import React, { FC, useContext } from 'react'
import { ChatBox } from '../box/ChatBox'
import styled from '@emotion/styled'
import { ChatDesktopPreview } from './preview/ChatDesktopPreview'
import { ChatDesktopTitle } from './ChatDesktopTitle'
import { useTranslation } from 'react-i18next'
import { ChatDesktopUpload } from './ChatDesktopUpload'
import { ChatQuoteCreateButton } from './ChatQuoteCreateButton'
import { ChatContext } from '../context/ChatContext'
import { ChatDesktopNewChatButton } from './ChatDesktopNewChatButton'

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  height: '100%',
  gridTemplateColumns: '340px auto',
  gridTemplateRows: '100%',
  gap: theme.spacing.md,
}))

const Column = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.sm,
  height: '100%',
}))

const CreateButton = styled(ChatQuoteCreateButton)({
  marginInline: 'auto',
})

const Feedback = styled('div')(({ theme }) => ({
  textAlign: 'center',
  fontSize: theme.fontSize.small,
  color: theme.colors.text.muted,
}))

export const ChatDesktop: FC = () => {
  const { t } = useTranslation()
  const { preview } = useContext(ChatContext)

  return (
    <Container>
      <Column>
        <ChatDesktopTitle>
          {t('Get a quote with us')}

          <ChatDesktopNewChatButton />
        </ChatDesktopTitle>
        <ChatBox />
        <ChatDesktopUpload />
      </Column>

      <Column>
        <ChatDesktopTitle>{t('Your quote')}</ChatDesktopTitle>
        <ChatDesktopPreview preview={preview} />

        <CreateButton />

        <Feedback>
          {t("Can't find an SKU or do some of them not match?")}{' '}
          <a href="/" target="_blank">
            {t('Contact us')}
          </a>
          .
        </Feedback>
      </Column>
    </Container>
  )
}
