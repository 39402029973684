import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { useChatBoxFile } from '../../hooks/useChatBoxFile'
import { ChatBoxFileConfirmationHeader } from './ChatBoxFileConfirmationHeader'
import { ChatBoxFileConfirmationBody } from './ChatBoxFileConfirmationBody'
import { Backdrop } from '../../../common/components/Backdrop'
import { ChatContext } from '../../context/ChatContext'
import { useKeyPress } from 'ahooks'

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing.lg,
}))

const Content = styled('div')(({ theme }) => ({
  position: 'relative',
  zIndex: 101,
  background: theme.colors.common.white,
  width: 340,
  maxWidth: '100%',
  textAlign: 'center',
  boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
}))

export const ChatBoxFileConfirmation: FC = () => {
  const file = useChatBoxFile()
  const { form } = useContext(ChatContext)

  useKeyPress('esc', () => {
    form.resetField('files')
  })

  if (!file) {
    return null
  }

  return (
    <Container>
      <Content>
        <ChatBoxFileConfirmationHeader file={file} />
        <ChatBoxFileConfirmationBody file={file} />
      </Content>

      <Backdrop
        onClick={() => {
          form.resetField('files')
        }}
      />
    </Container>
  )
}
