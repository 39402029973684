import { FC, useContext, useEffect, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { ChatBoxFormSubmitHandler } from './useChatBoxFormSubmitHandler'
import { ChatContext } from '../../context/ChatContext'
import mergeRefs from 'merge-refs'
import { useKeyPress } from 'ahooks'
import { ChatBoxContext } from '../ChatBoxContext'
import { useChatBoxDebounce } from '../useChatBoxDebounce'

const Textarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: 'none',
  width: '100%',
  lineHeight: '20px',
  paddingBlock: 5,
  paddingLeft: theme.spacing.md,
  paddingRight: 36,
  border: 'solid 1px ' + theme.colors.border.default,
  borderRadius: theme.shapes.borderRadius,
  wordBreak: 'break-all',
  '&:disabled': {
    background: theme.colors.common.black + '10',
    borderColor: theme.colors.border.default + '66',
  },
}))

export const ChatBoxFormTextField: FC<{ submitHandler: ChatBoxFormSubmitHandler }> = ({ submitHandler }) => {
  const { t } = useTranslation()
  const { form } = useContext(ChatContext)
  const { setTextAreaHeight, formSubmissionEnabled, formEnabled } = useContext(ChatBoxContext)
  const elementRef = useRef<HTMLTextAreaElement | null>(null)
  const register = form.register('message')
  const ref = mergeRefs(elementRef, register.ref)
  const disabled = useChatBoxDebounce(!formEnabled)

  useEffect(() => {
    if (!disabled) {
      elementRef.current?.focus()
    }
  }, [disabled])

  useKeyPress(
    'enter',
    async (event) => {
      event.preventDefault()

      if (formSubmissionEnabled) {
        await submitHandler(form.getValues())
      }
    },
    {
      target: elementRef,
      exactMatch: true,
    },
  )

  return (
    <Textarea
      placeholder={t('Write your message here')}
      autoFocus
      maxRows={10}
      disabled={disabled}
      {...register}
      ref={ref}
      onHeightChange={(height) => {
        setTextAreaHeight(height)
      }}
    />
  )
}
