import { FC } from 'react'
import styled from '@emotion/styled'
import { File } from 'lucide-react'
import { ApiChatMessageFile } from '../../../api/chats/types/ApiChatMessageFile'

const Container = styled('a')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  maxWidth: '100%',
  color: theme.colors.outgoing.contrastText,
}))

const Name = styled('div')(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: theme.fontSize.small,
}))

export const ChatBoxMessageFile: FC<{ file: ApiChatMessageFile }> = ({ file }) => {
  return (
    <Container href={file.link} target="_blank">
      <File size={12} strokeWidth={2.5} />
      <Name>{file.fileName}</Name>
    </Container>
  )
}
