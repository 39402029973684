import { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  maxWidth: '100%',
  minWidth: 32,
  minHeight: 32,
  paddingBlock: 6,
  paddingInline: 12,
  lineHeight: '20px',
  isolation: 'isolate',
  borderRadius: theme.shapes.borderRadius,
  '::before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    marginTop: -12,
    zIndex: -1,
    borderStyle: 'solid',
    borderWidth: '0 7.5px 13.0px 7.5px',
  },
}))

const IncomingContainer = styled(Container)(({ theme }) => ({
  marginInlineStart: 16,
  background: theme.colors.incoming.background,
  color: theme.colors.incoming.contrastText,
  '::before': {
    transform: 'rotate(-12deg)',
    left: 2,
    borderColor: `transparent transparent ${theme.colors.incoming.background} transparent`,
  },
}))

const OutgoingContainer = styled(Container)(({ theme }) => ({
  background: theme.colors.outgoing.background,
  color: theme.colors.outgoing.contrastText,
  '::before': {
    transform: 'rotate(12deg)',
    right: 2,
    borderColor: `transparent transparent ${theme.colors.outgoing.background} transparent`,
  },
}))

export const ChatBoxMessageContent: FC<PropsWithChildren<{ fromAssistant: boolean }>> = ({
  fromAssistant,
  children,
}) => {
  const Container = fromAssistant ? IncomingContainer : OutgoingContainer

  return <Container>{children}</Container>
}
