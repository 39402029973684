import styled from '@emotion/styled'
import React, { FC, PropsWithChildren, ReactNode } from 'react'

const Container = styled('li')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing.xs,
}))

export const Title = styled('span')(({ theme }) => ({
  fontWeight: theme.fontWeight.bold,
  ':after': {
    content: '":"',
  },
}))

export type ChatMobilePreviewDetailData = {
  title: ReactNode
  value: ReactNode
}

const Value = styled('span')()

export const ChatMobilePreviewDetail: FC<PropsWithChildren<{ title: ReactNode }>> = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Value>{children}</Value>
    </Container>
  )
}
