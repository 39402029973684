import { useMemo } from 'react'
import { ChatMessage } from '../types/ChatMessage'

export function useChatContextWaitingResponse(messages: ChatMessage[]): boolean {
  return useMemo(() => {
    const lastMessage = messages[messages.length - 1]

    if (lastMessage) {
      return !lastMessage.fromAssistant
    }

    return false
  }, [messages])
}
