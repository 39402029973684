import React, { FC } from 'react'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'
import { Caption } from '../../common/components/Caption'
import { Row } from '../../common/components/Row'
import { ChatBoxFileSelect } from '../box/file/ChatBoxFileSelect'
import { ChatQuoteCreateButton } from '../desktop/ChatQuoteCreateButton'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.sm,
  flexShrink: 0,
}))

export const ChatMobileFooter: FC = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <Caption>{t('Attach a file in PDF, Excel, or Image')}</Caption>

      <Row>
        <ChatBoxFileSelect />
        <ChatQuoteCreateButton />
      </Row>
    </Container>
  )
}
