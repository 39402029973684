import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { LoadingSpinner } from '../common/components/LoadingSpinner'
import { LoadingStatus } from '../common/types/LoadingStatus'
import { QuoteContext } from './QuoteContext'
import { QuotePdfReplacement } from './QuotePdfReplacement'

const Embed = styled('embed')({
  width: '100%',
  height: '100%',
})

export const QuotePdfEmbed: FC = () => {
  const [status, data] = useContext(QuoteContext).loadingState

  return (
    <>
      {status === LoadingStatus.Success ? (
        <Embed src={data.url} type="application/pdf" />
      ) : status === LoadingStatus.Failure ? (
        <QuotePdfReplacement />
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
}
