import { FC } from 'react'
import styled from '@emotion/styled'
import { ChatMobilePreviewDetail, ChatMobilePreviewDetailData } from './ChatMobilePreviewDetail'

export const Container = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.sm,
  margin: 0,
  padding: theme.spacing.md,
  background: theme.colors.background.muted,
}))

export const ChatMobilePreviewDetails: FC<{ details: ChatMobilePreviewDetailData[]; className?: string }> = ({
  details,
  className,
}) => {
  return (
    <Container className={className}>
      {details.map((detail, index) => (
        <ChatMobilePreviewDetail title={detail.title} key={index}>
          {detail.value}
        </ChatMobilePreviewDetail>
      ))}
    </Container>
  )
}
