import { FC } from 'react'
import styled from '@emotion/styled'
import { ChatBoxMessageBotImage } from './ChatBoxMessageBotImage'
import { ChatBoxMessageTime } from './ChatBoxMessageTime'

const Container = styled('footer')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing.sm,
}))

export const ChatBoxMessageFooter: FC<{ fromAssistant: boolean; date?: Date }> = ({ fromAssistant, date }) => {
  return (
    <Container>
      {fromAssistant && <ChatBoxMessageBotImage />}

      {date && <ChatBoxMessageTime date={date} />}
    </Container>
  )
}
