import { FC, useContext } from 'react'
import { Send } from 'lucide-react'
import styled from '@emotion/styled'
import { ResetButton } from '../../../common/components/ResetButton'
import { ChatBoxContext } from '../ChatBoxContext'

const Button = styled(ResetButton)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  display: 'flex',
  paddingBlock: theme.spacing.sm,
  paddingInline: theme.spacing.lg,
  borderRadius: theme.shapes.borderRadius,
  color: theme.colors.primary.main,
  '&:hover': {
    background: theme.colors.common.black + theme.alfa.hover,
  },
  '&:active': {
    background: theme.colors.common.black + theme.alfa.active,
  },
  '&:disabled': {
    color: theme.colors.text.disabled,
    background: 'transparent',
  },
}))

export const ChatBoxFormSubmitButton: FC = () => {
  const { formSubmissionEnabled } = useContext(ChatBoxContext)

  return (
    <Button type="submit" disabled={!formSubmissionEnabled}>
      <Send size={16} />
    </Button>
  )
}
