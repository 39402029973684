import { BehaviorSubject, map, Observable, Subject } from 'rxjs'
import { RxStomp, RxStompState } from '@stomp/rx-stomp'
import { IFrame, Stomp } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { chatWebsocketTopics } from './chatWebsocketTopics'
import { ApiChatPreview } from '../../api/chats/types/ApiChatPreview'
import { ChatWebsocketUrlFactory } from './ChatWebsocketUrlFactory'
import { ApiChatMessage } from '../../api/chats/types/ApiChatMessage'
import { ChatWebsocketSendMessage } from './types/ChatWebsocketSendMessage'
import toast from 'react-hot-toast'
import { t } from 'i18next'

export class ChatWebsocket {
  readonly preview: Observable<ApiChatPreview>
  readonly messages: Observable<ApiChatMessage>
  readonly connectionState: BehaviorSubject<RxStompState>
  readonly activated = new BehaviorSubject(false)
  readonly errors: Subject<IFrame>

  private readonly stomp: RxStomp

  constructor(private chatId: string) {
    this.stomp = new RxStomp(Stomp.over(() => new SockJS(ChatWebsocketUrlFactory.getUrl(this.chatId))))
    this.stomp.configure({
      reconnectDelay: 1000,
    })
    this.connectionState = this.stomp.connectionState$
    this.errors = this.stomp.stompErrors$

    this.preview = this.watch<ApiChatPreview>(chatWebsocketTopics.preview(this.chatId))
    this.messages = this.watch<ApiChatMessage>(chatWebsocketTopics.messages(this.chatId))

    this.activate()
  }

  async activate() {
    this.activated.next(true)
    return this.stomp.activate()
  }

  async deactivate() {
    this.activated.next(false)
    return this.stomp.deactivate()
  }

  async sendMessage(message: ChatWebsocketSendMessage) {
    try {
      this.stomp.publish({
        destination: chatWebsocketTopics.send(this.chatId),
        body: JSON.stringify(message),
      })
    } catch (error) {
      toast.error(t('Error sending message. Try again.'))

      throw error
    }
  }

  private watch<T>(destination: string): Observable<T> {
    return this.stomp.watch({ destination }).pipe(map((message) => JSON.parse(message.body)))
  }
}
