import {
  createContext,
  Dispatch,
  DispatchWithoutAction,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { ChatContext } from '../context/ChatContext'
import { useChatBoxConfirmation } from './useChatBoxConfirmation'

interface ChatBoxContextValue {
  textAreaHeight: number
  setTextAreaHeight: Dispatch<SetStateAction<number>>
  formEnabled: boolean
  formSubmissionEnabled: boolean
  confirmSending: DispatchWithoutAction
}

export const ChatBoxContext = createContext({} as ChatBoxContextValue)

function useChatBoxContextValue(): ChatBoxContextValue {
  const { connectionReady, form } = useContext(ChatContext)
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0)
  const [sendingConfirmed, confirmSending] = useChatBoxConfirmation()
  const formEnabled = connectionReady && sendingConfirmed
  const formSubmissionEnabled = formEnabled && form.formState.isValid

  return {
    textAreaHeight,
    setTextAreaHeight,
    formEnabled,
    formSubmissionEnabled,
    confirmSending,
  }
}

export const ChatBoxContextProvider: FC<PropsWithChildren> = ({ children }) => (
  <ChatBoxContext.Provider value={useChatBoxContextValue()}>{children}</ChatBoxContext.Provider>
)
