import { ChatBoxFormData } from '../../context/useChatContextForm'
import { useContext } from 'react'
import { ChatContext } from '../../context/ChatContext'
import { ChatBoxSendMessageFactory } from './ChatBoxSendMessageFactory'
import { ChatBoxContext } from '../ChatBoxContext'

export type ChatBoxFormSubmitHandler = (data: ChatBoxFormData) => Promise<void>

export function useChatBoxFormSubmitHandler(): ChatBoxFormSubmitHandler {
  const { form, websocket } = useContext(ChatContext)
  const { formSubmissionEnabled, confirmSending } = useContext(ChatBoxContext)

  return async (data: ChatBoxFormData) => {
    if (formSubmissionEnabled) {
      const message = await new ChatBoxSendMessageFactory(data).getMessage()

      if (message) {
        form.reset()

        await websocket.sendMessage(message)

        confirmSending()
      }
    }
  }
}
