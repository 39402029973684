import React, { FC } from 'react'
import { Global } from '@emotion/react'
import { useAppTheme } from './useAppTheme'
import 'sanitize.css'
import 'sanitize.css/typography.css'
import 'sanitize.css/forms.css'
import './fonts/stylesheet.css'

export const GlobalStyles: FC = () => {
  const theme = useAppTheme()

  return (
    <Global
      styles={{
        body: {
          fontFamily: 'Borna, sans-serif',
          fontSize: theme.fontSize.regular,
          color: theme.colors.text.primary,
        },
        button: {
          fontFamily: 'inherit',
          fontSize: 'inherit',
          '&:not(:disabled)': {
            cursor: 'pointer',
          },
        },
        a: {
          textDecoration: 'underline',
          color: theme.colors.common.link,
          '&:hover': {
            textDecoration: 'none',
          },
        },
      }}
    />
  )
}
