import styled from '@emotion/styled'

export const Backdrop = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  background: theme.colors.common.black + '55',
}))
