import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { routePaths } from '../../common/routing/routePaths'
import { useTranslation } from 'react-i18next'
import { Button } from '../../common/components/Button'

export const QuoteNewChatButton: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Button
      big
      onClick={() => {
        navigate(routePaths.init)
      }}
    >
      {t('New quote')}
    </Button>
  )
}
