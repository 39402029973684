import { BehaviorSubject } from 'rxjs'
import { useState } from 'react'
import { useObservable } from './useObservable'

export function useBehaviorSubject<T>(websocket: BehaviorSubject<T>): T {
  const [state, setState] = useState<T>(websocket.value)

  useObservable(websocket, setState)

  return state
}
