import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { FileWarning } from 'lucide-react'

export const ErrorOccurred: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <FileWarning size="1em" />

      {t('An error has occurred')}
    </>
  )
}
