import { FC, Fragment } from 'react'
import { ApiChatPreviewItem } from '../../../api/chats/types/ApiChatPreview'
import { useTranslation } from 'react-i18next'
import { Money } from '../../../common/components/Money'
import { ChatDesktopPreviewTable } from './ChatDesktopPreviewTable'
import { ChatPreviewComment } from '../../preview/ChatPreviewComment'
import styled from '@emotion/styled'

const LeftCell = styled('td')({
  '&&': {
    textAlign: 'left',
  },
})

export const ChatDesktopPreviewItems: FC<{ items: ApiChatPreviewItem[] | null }> = ({ items }) => {
  const { t } = useTranslation()

  return (
    <ChatDesktopPreviewTable>
      <thead>
        <tr>
          <th>{t('#')}</th>
          <th>{t('Description')}</th>
          <th>{t('Units')}</th>
          <th>{t('Quantity')}</th>
          <th>{t('Unit Price')}</th>
          <th>{t('% Discount')}</th>
          <th>{t('Subtotal')}</th>
          <th>{t('Delivery Date')}</th>
        </tr>
      </thead>

      <tbody>
        {items?.map((item, index) => (
          <Fragment key={index}>
            <tr>
              <td>{item.rownum}</td>
              <LeftCell>{item.description}</LeftCell>
              <td>{item.units}</td>
              <td>{item.quantity}</td>
              <td>
                <Money value={item.price} />
              </td>
              <td>{item.discount}</td>
              <td>
                <Money value={item.subtotal} />
              </td>
              <td>{item.date}</td>
            </tr>

            {item.comment && (
              <tr>
                <td></td>
                <LeftCell colSpan={7}>
                  <ChatPreviewComment>{item.comment}</ChatPreviewComment>
                </LeftCell>
              </tr>
            )}
          </Fragment>
        ))}
      </tbody>
    </ChatDesktopPreviewTable>
  )
}
