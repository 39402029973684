import React, { FC, useContext, useState } from 'react'
import styled from '@emotion/styled'
import { ResetButton } from '../../common/components/ResetButton'
import { RefreshCw } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { animations } from '../../common/animations'
import { apiClient } from '../../api/ApiClient'
import { routePaths } from '../../common/routing/routePaths'
import { useTranslation } from 'react-i18next'
import { ChatContext } from '../context/ChatContext'

const Container = styled(ResetButton)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing.sm,
  marginBlock: -theme.spacing.sm,
  '&:hover': {
    background: theme.colors.text.primary,
    color: theme.colors.background.default,
  },
  '&:active': {
    background: theme.colors.common.black,
  },
}))

const AnimatedIcon = styled(RefreshCw)({
  animation: `${animations.spin} 0.675s linear infinite`,
})

export const ChatDesktopNewChatButton: FC = () => {
  const { t } = useTranslation()
  const { chatId } = useContext(ChatContext)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const Icon = loading ? AnimatedIcon : RefreshCw

  return (
    <>
      <Container
        disabled={loading}
        type="button"
        onClick={async () => {
          setLoading(true)

          await apiClient.chats.cancelChat(chatId).finally(() => setLoading(false))

          navigate(routePaths.init)
        }}
        title={t('Create new chat')}
      >
        <Icon size="1em" />
      </Container>
    </>
  )
}
