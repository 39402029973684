import { ApiChatMessage } from '../../api/chats/types/ApiChatMessage'
import { ApiChatMessageType } from '../../api/chats/types/ApiChatMessageType'
import { ApiChatMessageFile } from '../../api/chats/types/ApiChatMessageFile'

export class ChatMessage {
  constructor(
    public id: string,
    public text: string,
    public fromAssistant: boolean,
    public date: Date,
    public file: ApiChatMessageFile | null,
  ) {}

  static fromApi(message: ApiChatMessage): ChatMessage {
    return new ChatMessage(
      message.id,
      message.text,
      message.type !== ApiChatMessageType.User,
      new Date(message.date),
      message.file,
    )
  }
}
