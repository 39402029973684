import { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { File, X } from 'lucide-react'
import { ResetButton } from '../../../common/components/ResetButton'
import { ChatContext } from '../../context/ChatContext'
import { Ellipsis } from '../../../common/components/Ellipsis'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  maxWidth: '100%',
  alignItems: 'center',
  gap: theme.spacing.sm,
  paddingInline: theme.spacing.sm,
  fontSize: theme.fontSize.small,
}))

const Icon = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: 6,
  borderRadius: '50%',
  background: theme.colors.secondary.main,
  color: theme.colors.secondary.contrastText,
}))

const Name = styled(Ellipsis)(({ theme }) => ({
  flexGrow: 1,
  fontWeight: theme.fontWeight.medium,
  fontSize: theme.fontSize.small,
}))

const Button = styled(ResetButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 6,
  borderRadius: '50%',
  color: theme.colors.primary.main,
  '&:hover': {
    background: theme.colors.primary.main + '10',
  },
  '&:active': {
    background: theme.colors.primary.main + '20',
  },
}))

export const ChatBoxFilePreview: FC<{ file: File }> = ({ file }) => {
  const { form } = useContext(ChatContext)

  return (
    <Container>
      <Icon>
        <File size={12} strokeWidth={2.5} />
      </Icon>

      <Name>{file.name}</Name>

      <Button
        onClick={() => {
          form.resetField('files')
        }}
      >
        <X size={12} strokeWidth={2.5} />
      </Button>
    </Container>
  )
}
