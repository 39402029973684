import { DESKTOP_BREAKPOINT_WIDTH } from './constants'

export const theme = {
  colors: {
    common: {
      black: '#000000',
      white: '#FFFFFF',
      link: '#0000FFB3',
    },
    background: {
      default: '#FFFFFF',
      muted: '#EFEFEF',
    },
    border: {
      default: '#707070',
      dotted: '#ADADAD',
    },
    text: {
      primary: '#343434',
      muted: '#0E0E0EB3',
      disabled: '#CACACA',
    },
    primary: {
      lighter: '#ff582e',
      main: '#FC4C1E',
      darken: '#ee4519',
      disabled: 'rgba(252, 76, 30, 0.4)',
      contrastText: '#FFFFFF',
    },
    secondary: {
      lighter: '#03d3d3',
      main: '#00CDCD',
      darken: '#03c0c0',
      disabled: 'rgba(0, 205, 205, 0.4)',
      contrastText: '#FFFFFF',
    },
    dark: {
      main: '#343434',
      darken: '#000',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#4FB62F',
      contrastText: '#FFFFFF',
    },
    incoming: {
      background: '#E5EEF6',
      contrastText: '#343434',
    },
    outgoing: {
      background: '#0557AB',
      contrastText: '#FFFFFF',
    },
  },
  fontSize: {
    extraSmall: 11,
    small: 12,
    regular: 14,
    large: 30,
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    bold: 600,
  },
  spacing: {
    xs: 4,
    sm: 8,
    md: 12,
    lg: 16,
    xl: 24,
  },
  breakpoints: {
    desktop: `@media (min-width: ${DESKTOP_BREAKPOINT_WIDTH}px)`,
  },
  shadows: {
    box: '0px 1px 13px #00000029',
  },
  shapes: {
    borderRadius: 16,
  },
  alfa: {
    hover: '05',
    active: '10',
  },
}

export type AppTheme = typeof theme
