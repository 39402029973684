import React, { FC, PropsWithChildren } from 'react'
import styled from '@emotion/styled'

const Container = styled.div(({ theme }) => ({
  height: '100svh',
  padding: theme.spacing.sm,
  [theme.breakpoints.desktop]: {
    padding: theme.spacing.md,
  },
}))

export const Root: FC<PropsWithChildren> = ({ children }) => {
  return <Container>{children}</Container>
}
