import { FC } from 'react'
import styled from '@emotion/styled'
import { ReactComponent as Logo } from '../../../logo.svg'

const Image = styled(Logo)(({ theme }) => ({
  fontSize: 20,
  color: theme.colors.primary.main,
}))

export const ChatBoxMessageBotImage: FC = () => {
  return <Image />
}
