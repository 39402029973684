import { FC, useContext } from 'react'
import { ChatContext } from '../../context/ChatContext'
import { ChatBoxFormSubmitButton } from './ChatBoxFormSubmitButton'
import { useChatBoxFormSubmitHandler } from './useChatBoxFormSubmitHandler'
import { ChatBoxFormTextField } from './ChatBoxFormTextField'
import styled from '@emotion/styled'

const Container = styled('div')({
  position: 'relative',
  display: 'flex',
})

export const ChatBoxForm: FC = () => {
  const { form } = useContext(ChatContext)
  const submitHandler = useChatBoxFormSubmitHandler()

  return (
    <form onSubmit={form.handleSubmit(submitHandler)}>
      <Container>
        <ChatBoxFormTextField submitHandler={submitHandler} />
        <ChatBoxFormSubmitButton />
      </Container>
    </form>
  )
}
