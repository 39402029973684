import { FC } from 'react'
import styled from '@emotion/styled'
import { QuotePdfReplacement } from './QuotePdfReplacement'
import { QuotePdfEmbed } from './QuotePdfEmbed'
import { pdfSupported } from '../common/pdfSupported'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing.sm,
  flexGrow: 1,
  flexBasis: '100%',
  overflow: 'auto',
  background: theme.colors.background.muted,
}))

export const QuotePdf: FC = () => {
  return <Container>{pdfSupported ? <QuotePdfEmbed /> : <QuotePdfReplacement />}</Container>
}
