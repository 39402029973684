import { createContext, FC, PropsWithChildren } from 'react'
import { ChatMessage } from '../types/ChatMessage'
import { ApiChatPreview } from '../../api/chats/types/ApiChatPreview'
import { ChatWebsocket } from '../websocket/ChatWebsocket'
import { useChatContextWaitingResponse } from './useChatContextWaitingResponse'
import { ChatBoxFormData, useChatContextForm } from './useChatContextForm'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useChatMessagesRecovery } from '../recovery/useChatMessagesRecovery'
import { useChatContextPreview } from './useChatContextPreview'
import { useChatContextWebsocket } from './useChatContextWebsocket'
import { useChatContextMessages } from './useChatContextMessages'
import { useBehaviorSubject } from '../../common/hooks/useBehaviorSubject'
import { RxStompState } from '@stomp/rx-stomp'

type ChatContextValue = {
  chatId: string
  websocket: ChatWebsocket
  connectionReady: boolean
  messages: ChatMessage[]
  messagesRecovering: boolean
  waitingResponse: boolean
  preview: ApiChatPreview | null
  form: UseFormReturn<ChatBoxFormData>
}

export const ChatContext = createContext({} as ChatContextValue)

function useChatContextValue(chatId: string): ChatContextValue {
  const websocket = useChatContextWebsocket(chatId)
  const connectionOpen = useBehaviorSubject(websocket.connectionState) === RxStompState.OPEN
  const [messages, setMessages] = useChatContextMessages(websocket)
  const waitingResponse = useChatContextWaitingResponse(messages)
  const preview = useChatContextPreview(websocket)
  const form = useChatContextForm()
  const messagesRecovering = useChatMessagesRecovery({
    websocket,
    chatId,
    messages,
    setMessages,
  })
  const connectionReady = connectionOpen && !messagesRecovering && !waitingResponse

  return {
    chatId,
    websocket,
    connectionReady,
    messages,
    messagesRecovering,
    waitingResponse,
    preview,
    form,
  }
}

export const ChatContextProvider: FC<PropsWithChildren<{ chatId: string }>> = ({ chatId, children }) => {
  const value = useChatContextValue(chatId)

  return (
    <ChatContext.Provider value={value}>
      <FormProvider {...value.form}>{children}</FormProvider>
    </ChatContext.Provider>
  )
}
