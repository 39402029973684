import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, UseFormReturn } from 'react-hook-form'

const schema = z
  .object({
    message: z.string().nullable(),
    files: z.instanceof(FileList).nullable(),
  })
  .refine((data) => data.message || data.files, 'Message or file must be specified')

export type ChatBoxFormData = z.infer<typeof schema>

export function useChatContextForm(): UseFormReturn<ChatBoxFormData> {
  return useForm<ChatBoxFormData>({
    resolver: zodResolver(schema),
    defaultValues: {
      message: null,
      files: null,
    },
  })
}
