import { FC } from 'react'
import styled from '@emotion/styled'
import { Location, useLocation } from 'react-router-dom'
import { ApiQuote } from '../api/quotes/types/ApiQuote'
import { QuoteContextProvider } from './QuoteContext'
import { QuoteHeader } from './buttons/QuoteHeader'
import { QuotePdf } from './QuotePdf'
import { QuoteFooter } from './buttons/QuoteFooter'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.lg,
  height: '100%',
  maxWidth: 920,
  marginInline: 'auto',
  overflow: 'hidden',
}))

export const Quote: FC = () => {
  const { state }: Location = useLocation()

  return (
    <Container>
      {isQuote(state) && (
        <QuoteContextProvider quote={state}>
          <QuoteHeader />
          <QuotePdf />
          <QuoteFooter />
        </QuoteContextProvider>
      )}
    </Container>
  )
}

function isQuote(value: any | ApiQuote): value is ApiQuote {
  return value && value.totalInfo && Array.isArray(value.data)
}
