import React, { FC, useContext } from 'react'
import { ChatBox } from '../box/ChatBox'
import styled from '@emotion/styled'
import { ChatMobileFooter } from './ChatMobileFooter'
import { ChatMobilePreview } from './preview/ChatMobilePreview'
import { ChatContext } from '../context/ChatContext'
import { ChatMobileContextProvider } from './ChatMobileContext'

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.sm,
  height: '100%',
}))

export const ChatMobile: FC = () => {
  const { preview } = useContext(ChatContext)

  return (
    <ChatMobileContextProvider>
      <Container>
        {preview && <ChatMobilePreview preview={preview} />}

        <ChatBox />
        <ChatMobileFooter />
      </Container>
    </ChatMobileContextProvider>
  )
}
