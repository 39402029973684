import { useContext } from 'react'
import { ChatContext } from '../context/ChatContext'

export function useChatBoxFile(): File | null {
  const { form } = useContext(ChatContext)
  const files = form.watch('files')

  if (!files) {
    return null
  }

  const [file] = files

  return file || null
}
