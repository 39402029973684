import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translation from './locales/es/translation.json'

export const t = i18n.use(initReactI18next).init({
  lng: process.env.NODE_ENV === 'production' ? 'es' : 'en',
  initImmediate: true,
  keySeparator: false,
  nsSeparator: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    es: {
      translation,
    },
  },
})
