import { Dispatch, SetStateAction } from 'react'
import { ChatMessage } from '../types/ChatMessage'
import { apiClient } from '../../api/ApiClient'

export class ChatMessagesRecoveryWorker {
  constructor(
    private chatId: string,
    private setMessages: Dispatch<SetStateAction<ChatMessage[]>>,
  ) {}

  async recoverMessages(lastMessage?: ChatMessage): Promise<void> {
    const newMessages = await this.loadNewMessages(lastMessage)

    this.setMessages((currentMessages) => this.mergeMessages(currentMessages, newMessages, lastMessage))
  }

  private mergeMessages(
    currentMessages: ChatMessage[],
    newMessages: ChatMessage[],
    lastMessage?: ChatMessage,
  ): ChatMessage[] {
    if (lastMessage) {
      const index = currentMessages.indexOf(lastMessage)

      if (index !== -1) {
        return this.insertMessagesAfterIndex(currentMessages, index, newMessages)
      }
    }

    return currentMessages.concat(newMessages)
  }

  private insertMessagesAfterIndex(currentMessages: ChatMessage[], index: number, newMessages: ChatMessage[]) {
    const resultingMessages = currentMessages.slice()

    resultingMessages.splice(index + 1, 0, ...newMessages)

    return resultingMessages
  }

  private async loadNewMessages(lastMessage?: ChatMessage): Promise<ChatMessage[]> {
    const response = await apiClient.chats.getChatMessages({
      chatId: this.chatId,
      lastMessageId: lastMessage?.id,
    })

    return response.messages ? response.messages.map((message) => ChatMessage.fromApi(message)) : []
  }
}
