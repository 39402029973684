import { FC, useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../common/components/LoadingSpinner'
import styled from '@emotion/styled'
import { apiClient } from '../api/ApiClient'
import { useTranslation } from 'react-i18next'

const LoaderContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing.sm,
  marginTop: '20vh',
}))

const ErrorContainer = styled('div')`
  text-align: center;
  margin-top: 5rem;
`;

export const PurchaseOrder: FC = () => {
  const { t } = useTranslation();
  const { quoteId } = useParams() as { quoteId: string }
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    const redirectUrl = searchParams.get('redirectUrl')!;
    apiClient.quotes.createOrder({ quoteId })
      .then(() => {
        window.location.replace(redirectUrl)
      })
      .catch(() => {
        setError(true);
      })
  }, [quoteId, searchParams])

  if (error) {
    return (
      <ErrorContainer>
        <h1>{t('Something went wrong')}</h1>
        <p>{t('We are not able to perform operation')}</p>
      </ErrorContainer>
    );
  }


  return (
    <LoaderContainer>
      <LoadingSpinner />
    </LoaderContainer>
  );
}
