import React, { FC, useContext } from 'react'
import styled from '@emotion/styled'
import { ChatMobilePreviewTotal } from './ChatMobilePreviewTotal'
import { ChatMobilePreviewItem } from './ChatMobilePreviewItem'
import { ApiChatPreview } from '../../../api/chats/types/ApiChatPreview'
import { ChatMobileContext } from '../ChatMobileContext'
import { ChatMobilePreviewMaximizedToggle } from './ChatMobilePreviewMaximizedToggle'

const MinimizedContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing.sm,
  flexBasis: '100%',
  overflow: 'auto',
}))

const MaximizedContainer = styled(MinimizedContainer)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,
  height: '100svh',
  padding: theme.spacing.sm,
  background: theme.colors.background.default,
}))

export const ChatMobilePreview: FC<{ preview: ApiChatPreview }> = ({ preview }) => {
  const { previewMaximized } = useContext(ChatMobileContext)
  const Container = previewMaximized ? MaximizedContainer : MinimizedContainer

  return (
    <>
      <Container>
        {preview.data.map((item, index) => (
          <ChatMobilePreviewItem item={item} key={index} />
        ))}

        <ChatMobilePreviewTotal totalInfo={preview.totalInfo} />
      </Container>

      <ChatMobilePreviewMaximizedToggle />
    </>
  )
}
