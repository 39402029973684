import { FC } from 'react'
import { useMount } from 'ahooks'
import { To, useNavigate } from 'react-router-dom'
import { routePaths } from '../common/routing/routePaths'
import { apiClient } from '../api/ApiClient'

export const ChatInit: FC = () => {
  const navigate = useNavigate()

  useMount(async () => {
    let to: To
    const purchasePayload = getPurchaseOrderPayload()

    if (purchasePayload) {
      to = {
        pathname: routePaths.purchaseOrder(purchasePayload.quoteId),
        search: `?redirectUrl=${purchasePayload.redirectUrl}`,
      }
    } else {
      const { id } = await apiClient.chats.getActiveChat()

      to = routePaths.chat(id)
    }

    navigate(to)
  })

  return null
}

type PurchaseOrderPayload = {
  quoteId: string;
  redirectUrl: string;
}

function getPurchaseOrderPayload(): PurchaseOrderPayload | null {
  const searchParams = new URLSearchParams(window.location.search)
  const quoteId = searchParams.get('quoteId')
  const redirectUrl = searchParams.get('redirectUrl')

  return searchParams.get('action') === 'purchase-order' && quoteId && redirectUrl ? { quoteId, redirectUrl } : null
}
